<script setup>
</script>

<template>
  <crud-details-page
    api="boss-event"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Name -->
        <crud-field-text
          api-field-name="Name"
          required
        />
        <!-- NameTag -->
        <crud-field-selector-client-strings
          api-field-name="NameTag"
          required
        />
        <!-- DurationHours -->
        <crud-field-number api-field-name="DurationHours" />
        <!-- Mode -->
        <crud-field-select
          api-field-name="Mode"
          options-enum="enums.BossEventMode"
        />
        <!-- GoldMultiplier -->
        <crud-field-number api-field-name="GoldMultiplier" />
        <!-- GoldCritMultiplier -->
        <crud-field-number api-field-name="GoldCritMultiplier" />
        <!-- XpBonusPct -->
        <crud-field-number api-field-name="XpBonusPct" />
        <!-- CritXpBonusPct -->
        <crud-field-number api-field-name="CritXpBonusPct" />
        <!-- InfoPopupDescriptionTag -->
        <crud-field-selector-client-strings api-field-name="InfoPopupDescriptionTag" />
      </fields-col>
      <fields-col :sm="8">
        <!-- ForNewbies -->
        <crud-field-switcher api-field-name="ForNewbies" />
        <!-- GlobalReward -->
        <crud-field-selector-boss-event-global-reward api-field-name="GlobalReward" />
        <!-- Divisions -->
        <crud-field-text
          api-field-name="Divisions"
          label="Divisions (as csv, empty means all)"
        />
        <!-- CritGame -->
        <crud-field-text
          api-field-name="CritGame"
          label="Crit game settings"
        />
        <!-- GuildRushTeam2Skins -->
        <crud-field-text
          api-field-name="GuildRushTeam2Skins"
          label="Guild rush team 2 skins"
        />
        <!-- SkirmishReminderAssetPopup -->
        <crud-field-selector-files api-field-name="SkirmishReminderAssetPopup" />
        <!-- AssetPopup -->
        <crud-field-selector-files api-field-name="AssetPopup" />
        <!-- AssetLocation -->
        <crud-field-selector-files api-field-name="AssetLocation" />
        <!-- AssetHomeIcon -->
        <crud-field-selector-files api-field-name="AssetHomeIcon" />
      </fields-col>
      <fields-col :sm="8">
        <!-- AssetHomeBanner -->
        <crud-field-selector-files api-field-name="AssetHomeBanner" />
        <!-- AssetBoss -->
        <crud-field-selector-files api-field-name="AssetBoss" />
        <!-- AssetCritPanelBackground -->
        <crud-field-selector-files api-field-name="AssetCritPanelBackground" />
        <!-- AssetRankingBanner -->
        <crud-field-selector-files api-field-name="AssetRankingBanner" />
        <!-- AssetRankingBackground -->
        <crud-field-selector-files api-field-name="AssetRankingBackground" />
        <!-- AssetGlobalRankingBanner -->
        <crud-field-selector-files api-field-name="AssetGlobalRankingBanner" />
        <!-- AssetGlobalRankingBackground -->
        <crud-field-selector-files api-field-name="AssetGlobalRankingBackground" />
      </fields-col>
    </template>
  </crud-details-page>
</template>
